<template>
  <div>
    <title>SIM INVENTORY ~ TRACEBILITY PRODUCTION BY OUTGOING</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Tracebility Production By Outgoing
        <br />
        <h4>
          The following is a list of data tracebility production by outgoing
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div v-if="isExist" class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">Tracebility Production By Outgoing</h3>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <!-- <th>Idx</th> -->
                      <th>Outgoing Number</th>
                      <th>Receipt Number</th>
                      <th>Receipt Date</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Supplier Name</th>
                      <th>Item Qty</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <!-- <td>Idx</td> -->
                      <td>
                        {{ outgoing }}
                      </td>
                      <td>{{ recnumb }}</td>
                      <td>{{ recdate }}</td>
                      <td>{{ kodebarang }}</td>
                      <td>{{ namabarang }}</td>
                      <td>{{ namasupplier }}</td>
                      <td>{{ qty }} &nbsp; {{ unit }}</td>
                      <td>{{ tipe }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
        <div v-else class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list"></i>
              <h3 class="box-title">Tracebility Production By Outgoing</h3>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <!-- <th>Idx</th> -->
                      <th>Outgoing Number</th>
                      <th>Receipt Number</th>
                      <th>Receipt Date</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Supplier Name</th>
                      <th>Item Qty</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="8" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",

  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged: {},
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      outgoing: "",
      recnumb: "",
      recdate: "",
      kodebarang: "",
      namabarang: "",
      namasupplier: "",
      qty: "",
      unit: "",
      tipe: "",
      isExist: true
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      const urlApiGETdetail =
        this.$apiurl +
        "tracebility_hasil_jadi/getproses_produksibyref_id_outgoing?id=" +
        this.$route.params.id;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          //   console.log(resp);
          if (resp.data.pesan == "Data not found") {
            this.isExist = false;
            this.loading = false;
          } else {
            this.isExist = true;
            this.outgoing = resp.data.data.outgoing_number;
            this.recnumb = resp.data.data.receipt_number;
            this.recdate = resp.data.data.receipt_date;
            this.kodebarang = resp.data.data.kode_barang;
            this.namabarang = resp.data.data.nama_barang;
            this.namasupplier = resp.data.data.nama_supplier;
            this.qty = resp.data.data.qty;
            this.unit = resp.data.data.unit;
            this.tipe = resp.data.data.tipe;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
